<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter une annonce
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier une annonce
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label for="categorie">Catégorie</label>
                      <select
                        id="categorie"
                        v-model="form.categorie"
                        class="form-control"
                        :class="{ 'is-invalid': errors.categorie }"
                      >
                        <option value="">Sélectionner</option>
                        <option value="location">Location</option>
                        <option value="vente">Vente</option>
                      </select>
                      <span
                        class="form-text text-danger"
                        v-if="errors.categorie"
                        >{{ errors.categorie[0] }}</span
                      >
                    </div>
                    <div class="col-md-2">
                      <label>Sup. (m²)</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="form.superficie"
                        :class="{ 'is-invalid': errors.superficie }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.superficie"
                        >{{ errors.superficie[0] }}</span
                      >
                    </div>
                    <div class="col-md-7">
                      <div class="row" v-if="form.categorie === 'vente'">
                        <div class="col-md-5 offset-2">
                          <label>Prix standard</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.prix_standard"
                            :class="{ 'is-invalid': errors.prix_standard }"
                            v-money="form.prix_standard"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.prix_standard"
                            >{{ errors.prix_standard[0] }}</span
                          >
                        </div>
                        <div class="col-md-5 ">
                          <label>Prix final</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.prix_final"
                            :class="{ 'is-invalid': errors.prix_final }"
                            v-money="form.prix_final"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.prix_final"
                            >{{ errors.prix_final[0] }}</span
                          >
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-4">
                          <label>Coût mensuel</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.cout_mensuel"
                            :class="{ 'is-invalid': errors.cout_mensuel }"
                            v-money="form.cout_mensuel"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.cout_mensuel"
                            >{{ errors.cout_mensuel[0] }}</span
                          >
                        </div>
                        <div class="col-md-4">
                          <label>Avance</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.avance"
                            :class="{ 'is-invalid': errors.avance }"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.avance"
                            >{{ errors.avance[0] }}</span
                          >
                        </div>
                        <div class="col-md-4">
                          <label>Montant caution</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.montant_caution"
                            :class="{ 'is-invalid': errors.montant_caution }"
                            v-money-input="form.montant_caution"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.montant_caution"
                            >{{ errors.montant_caution[0] }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label>Nbre de chambre</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="form.nbre_chambre"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.nbre_chambre"
                        >{{ errors.nbre_chambre[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Type</label>
                      <multiselect
                        v-model="form.type"
                        :options="types"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un type"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setTypeId()"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.type_id"
                        >{{ errors.type_id[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label for="">Ville</label>
                      <multiselect
                        v-model="form.ville"
                        :options="option.villes"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        :allow-empty="false"
                        placeholder="Choisir une ville"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setVilleId()"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.ville_id"
                        >{{ errors.ville_id[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label for="">Quartier</label>
                      <multiselect
                        v-model="form.quartier"
                        :maxHeight="200"
                        :options="option.quartiers"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un quartier"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setQuartierId()"
                        ><span slot="noOptions"
                          >La liste est vide</span
                        ></multiselect
                      >
                      <span
                        class="form-text text-danger"
                        v-if="errors.quartier_id"
                        >{{ errors.quartier_id[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <div class="row mt-3 mb-3">
                        <div class="col-md-5">
                          <label for="">Agents immobiliers</label>
                          <multiselect
                            v-model="form.user"
                            :maxHeight="200"
                            :options="users"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :hide-selected="true"
                            placeholder="Rechercher par nom"
                            label="nom"
                            track-by="id"
                            select-label=""
                            deselect-label=""
                            @input="setEstateId()"
                            ><span slot="noOptions"
                              >La liste est vide</span
                            ></multiselect
                          >
                          <span
                            class="form-text text-danger"
                            v-if="errors.user_id"
                            >{{ errors.user_id[0] }}</span
                          >
                        </div>
                        <div class="col-md-4">
                          <label>Latitude</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.lat"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.lat"
                            >{{ errors.lat[0] }}</span
                          >
                        </div>
                        <div class="col-md-3">
                          <label>Longitude</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.long"
                          />
                          <span
                            class="form-text text-danger"
                            v-if="errors.long"
                            >{{ errors.long[0] }}</span
                          >
                        </div>
                      </div>
                      <div class="form-group row mt-3 mb-3">
                        <div class="col-md-4">
                          <label>Balcon</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.balcon"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.balcon"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.balcon"
                              >{{ errors.balcon[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Climatisation</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.climatiseurs"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.climatiseurs"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.climatiseurs"
                              >{{ errors.climatiseurs[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Eau courante</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.eau_courante"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.eau_courante"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.eau_courante"
                              >{{ errors.eau_courante[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mt-4 mb-3">
                        <div class="col-md-4">
                          <label>Meuble</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.meuble"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.meuble"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.meuble"
                              >{{ errors.meuble[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Déja loué ?</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.location"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.location"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.location"
                              >{{ errors.location[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Cour</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.cour"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.cour"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.cour"
                              >{{ errors.cour[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row mt-3 mb-3">
                        <div class="col-md-4">
                          <label>Cuisine interne ?</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.cuisine_int"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.cuisine_int"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.cuisine_int"
                              >{{ errors.cuisine_int[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Cuisine externe ?</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.cuisine_ext"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.cuisine_ext"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.cuisine_ext"
                              >{{ errors.cuisine_ext[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>WC Douche Externe ?</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.wc_douche_ext"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.wc_douche_ext"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.wc_douche_ext"
                              >{{ errors.wc_douche_ext[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mt-3 mb-3">
                        <div class="col-md-4">
                          <div v-show="form.cuisine_int === '1'">
                            <label>Nombre cuisine interne</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.nbre_cuisine_int"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.nbre_cuisine_int"
                              >{{ errors.nbre_cuisine_int[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div v-show="form.cuisine_ext === '1'">
                            <label>Nombre cuisine externe</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.nbre_cuisine_ext"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.nbre_cuisine_ext"
                              >{{ errors.nbre_cuisine_ext[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div v-show="form.wc_douche_ext === '1'">
                            <label>Nbre WC Dche Externe</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.nbre_wc_douche_ext"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.nbre_wc_douche_ext"
                              >{{ errors.nbre_wc_douche_ext[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mt-3 mb-3">
                        <div class="col-md-4">
                          <label>Terasse</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.terasse"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.terasse"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.terasse"
                              >{{ errors.terasse[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Etage</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.etage"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.etage"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.etage"
                              >{{ errors.etage[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Garage</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.garage"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.garage"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.garage"
                              >{{ errors.garage[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mt-3 mb-3">
                        <div class="col-md-4">
                          <div v-show="form.terasse === '1'">
                            <label>Nombre de terasse</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.nbre_terasse"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.nbre_terasse"
                              >{{ errors.nbre_terasse[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div v-show="form.etage === '1'">
                            <label>Nombre Etage</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.num_etage"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.num_etage"
                              >{{ errors.num_etage[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div v-show="form.garage === '1'">
                            <label>Nombre de garage</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.nbre_garage"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.nbre_garage"
                              >{{ errors.nbre_garage[0] }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="form-group row mt-3 mb-3">
                        <div class="col-md-4">
                          <label>WC Douche Interne ?</label>
                          <div class="radio-inline">
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.wc_douche_int"
                                value="1"
                              />
                              <span></span>OUI</label
                            >
                            <label class="radio radio-solid">
                              <input
                                type="radio"
                                v-model="form.wc_douche_int"
                                value="0"
                              />
                              <span></span>NON</label
                            >
                            <span
                              class="form-text text-danger"
                              v-if="errors.wc_douche_int"
                              >{{ errors.wc_douche_int[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div v-show="form.wc_douche_int === '1'">
                            <label>Nbre WC Dche Interne</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="form.nbre_wc_douche_int"
                            />
                            <span
                              class="form-text text-danger"
                              v-if="errors.nbre_wc_douche_int"
                              >{{ errors.nbre_wc_douche_int[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label for="">Tags</label>
                      <multiselect
                        v-model="form.tags"
                        tag-placeholder="Ajouter un tag"
                        placeholder="Selectionner tag"
                        label="name"
                        track-by="id"
                        :options="tags"
                        :multiple="true"
                        :taggable="true"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-3 mt-4">
                      <label for="">Ajouter image(s)</label>
                      <input
                        type="file"
                        @change="imageChange"
                        class="hidden form-control-file"
                        name="image"
                        ref="files"
                        multiple
                        id="files"
                        max="5"
                        accept="image/*"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.images"
                        >{{ errors.images[0] }}</span
                      >
                    </div>
                    <div class="col-md-3 mt-4">
                      <label for="">Ajouter documents(s)</label>
                      <input
                        type="file"
                        @change="documentChange"
                        accept="application/pdf"
                        class="hidden form-control-file"
                        name="document"
                        ref="documents"
                        multiple
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="">Description</label>
                      <ckeditor
                        :editor="editor"
                        v-model="form.description"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="kt_login_signin_submit"
                        class="btn btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <router-link
                        v-if="$route.meta.mode === 'update'"
                        :to="$route.meta.back"
                        class="btn btn-secondary"
                      >
                        Annuler
                      </router-link>
                      <button type="reset" v-else class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { _ } from "vue-underscore";
import { mapGetters } from "vuex";

export default {
  name: "Form",
  data() {
    return {
      initialize: "ad/create",
      store: "ad",
      method: "post",
      form: {
        ville: {
          id: "",
          quartiers: []
        }
      },
      option: {
        villes: [],
        quartiers: [],
        users: []
      },
      tag: [],
      value: [],
      tags: [],
      images: "",
      users: [],
      videos: [],
      documents: [],
      types: [],
      success: false,
      has_error: false,
      error: "",
      errors: {},
      isValidData: null,
      editor: ClassicEditor,
      editorConfig: {
        fontFamily: {
          options: [
            "default",
            "Ubuntu, Arial, sans-serif",
            "Ubuntu Mono, Courier New, Courier, monospace"
          ]
        },
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "undo",
          "redo",
          "blockQuote",
          "|",
          "insertTable",
          "tableColumn",
          "tableRow",
          "mergeTableCells"
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      // tags: "getAnnonceTags",
      // types: "getAnnonceTypes",
      // option: "getAnnonceOptions"
      // form: "getFormAnnonce",
    })
  },
  beforeCreate() {
    // if (this.$route.meta.mode === "update") {
    //   this.$store.dispatch("editAnnonce", this.$route.params.id);
    // } else {
    //   this.$store.dispatch("createAnnonce");
    // }
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "ad/" + this.$route.params.id + "/edit";
      this.store = "ad/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.option = response.data.option;
        vm.types = response.data.types;
        vm.tags = response.data.tags;
        vm.users = response.data.users;
      });
    },
    fetchDataQuartiers() {
      if (this.form.ville.id !== undefined) {
        let vm = this;
        let laregion;
        laregion = vm.option.villes.filter(v => {
          return parseInt(vm.form.ville.id) === parseInt(v.id);
        });
        vm.option.quartiers = laregion[0].quartiers;
        if (vm.form.quartier.id !== "") {
          if (vm.form.ville.id !== vm.form.quartier.ville_id) {
            vm.form.quartier = "";
          }
        }
      }
    },
    setQuartierId() {
      this.form.quartier_id = this.form.quartier.id;
    },
    setVilleId() {
      this.form.ville_id = this.form.ville.id;
    },
    setTypeId() {
      this.form.type_id = this.form.type.id;
    },
    setEstateId() {
      this.form.user_id = this.form.user.id;
    },
    imageChange() {
      this.images = this.$refs.files.files;
    },
    /*videoChange() {
      for (let i = 0; i < this.$refs.videos.files.length; i++) {
        this.videos.push(this.$refs.videos.files[i]);
      }
    },*/
    documentChange() {
      for (let i = 0; i < this.$refs.documents.files.length; i++) {
        this.documents.push(this.$refs.documents.files[i]);
      }
    },
    save() {
      let vm = this;
      let formData = new FormData();
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );

      for (let i = 0; i < this.videos.length; i++) {
        let file = vm.videos[i];
        formData.append("videos[" + i + "]", file);
      }
      for (let i = 0; i < this.documents.length; i++) {
        let file = vm.documents[i];
        formData.append("documents[" + i + "]", file);
      }

      let config = { headers: { "content-type": "multipart/form-data" } };

      for (let i = 0; i < this.images.length; i++) {
        let file = vm.images[i];
        formData.append("images[" + i + "]", file);
      }

      // Object.entries(this.form).forEach(([key, value]) => {
      //   formData.append(key, value);
      //   if (key === "tags") {
      //     formData.append(key, JSON.stringify(value));
      //   }
      // });

      _.forEach(this.form, (value, key) => {
        formData.append(key, value);
        if (key === "tags") {
          formData.append(key, JSON.stringify(value));
        }
      });

      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }

      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            if (vm.$route.meta.mode === "create") {
              Swal.fire({
                title: "Success",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            } else {
              Swal.fire({
                title: "Success",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            }
          }
        })
        .catch(error => {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    }
  },
  watch: {
    "form.ville.id": "fetchDataQuartiers"
  }
};
</script>

<style scoped></style>
